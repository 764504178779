import TermsPage from "./system/_termsPage";

export default TermsPage

export const frontmatter = {
  pageMetaData: {
    refKey: "terms",
    title: "Terms and Conditions",
    description: "Terms and Conditions",
    author: "simplebackups.com"
  }
}